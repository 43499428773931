<!-- Finder particular de Atlas -->

<template>
  <div class="atlasF" v-if="loaded">
    <div class="conflex" style="justify-content:center">
      <v-sheet :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera">
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader">
          </baseHeader>
        </div>


        <!-- Contenido -->
        <div class="contenedor">

          <!-- <div v-html="info"></div> -->

          <!-- Filtro de Búsqueda -->
          <div class="columna">
            <div style="display:flex; width:35rem">
              <uiText
                v-model="ct.name[2]"
                :label="ct.name[1]"
                @Enter="eventFiltro('buscar')">
              </uiText>

              <v-btn
                style="margin-left:5px"
                v-bind="$cfe.btn.busca"
                @click="eventFiltro('buscar')">
                <v-icon dark>{{ $cfe.btn.busca.icono }}</v-icon>
              </v-btn>
            </div>
          </div>

          <!-- Grid -->
          <baseGridMD
            :store="storeName"
            :headers="headerGrid"
            :items-per-page="-1"
            dense
            @onEvent="execAccion">

              <!-- Slot campo acciones del Grid -->
              <template v-slot:acciones="{ item }">
                <baseBtraMto
                  :perm="permMto"
                  :modulo="btRowCfg"
                  @onEvent="execAccion({ ...$event, ...{ item:item }})">
                </baseBtraMto>
              </template>

          </baseGridMD>
        </div>
      </v-sheet>

      <component
        :is="componenteDinamico"
        :syncDisparo="syncDisparoSend"
        :syncAccion="syncAccionSend"
        syncUpdate
        :storeRaiz="storeName"
        :masterStore="storeName">
      </component>
    </div>
  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto";

  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, baseBtraMto },
    props: {},

    data() {
      return {
        stIni: {
          api: "atlasF",
          titulo:"·Busqueda de Atlas",
          name:"atlasF",
          mView:'',
          pView:[],
          field2Preserve:'name'
        },

      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        this.headers = [{
          name:[],
          fields:[
            { text: "Acciones", value: "acciones", sortable: false, slot:true, width: "19%" },
            { text: "Nombre", value: "name", width: "70%" }
          ]},
        ];

        // configuro cabecera Grid
        this.headerGrid= this.headerSet();
      },


    }
  };
</script>


